import React from "react";
import { AuthProvider } from "./contexts/authContext";
import { OrderProvider } from "./contexts/OrderContext";
import Router from "./Router";

function App() {
  return (
    <AuthProvider>
      <OrderProvider>
        <Router />
      </OrderProvider>
    </AuthProvider>
  );
}
export default App;
