export const ORDER_SUMMARY_URL = "/order-summary";
export const NOT_FOUND_URL = "/404";
export const ERROR_URL = "/problem";
export const ACTIVATION_URL = "/activation";
export const CONTACT_US_URL = '/contact-us';
export const PUBLIC_BASE_URL = "/";
export const PAYMENT_OK = "/success";
export const AUTH_LOGIN = '/login';
export const AUTH_REGISTER = '/register';
export const AUTH_FORGOT_PASSWORD = '/forgot-password'
export const FAQ = "/faq";
export const TERMS_AND_CONDITION_URL = "/terms";
export const PRIVACY_POLICY_URL = "/privacy";
export const PATH_URLS = [
  PUBLIC_BASE_URL,

  // Orders & subscriptions routes
  ORDER_SUMMARY_URL,
  ACTIVATION_URL,

  // Gadgets routes
  FAQ,
  TERMS_AND_CONDITION_URL,
  PRIVACY_POLICY_URL,
  CONTACT_US_URL,

  // Auth routes
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_FORGOT_PASSWORD,

  // Error routes
  PAYMENT_OK,
  
  ERROR_URL,
  NOT_FOUND_URL,
];
