import { useHistory } from "react-router";
import { redirect } from "../../adapters/redirect";
import { PUBLIC_BASE_URL } from "../../routing/urls";
import "./problemModel.css";
import TailwindButton from "../../components/Button/TailwindButton";
import { Col, Label } from "../../components/ui";
import Logo from "../../components/Logo/Logo";
import img404 from "../../assets/img/😱.svg";
const ProblemModel = () => {
  const history = useHistory();
  return (
    <div className="flex justify-center items-center absolute inset-0 h-screen w-screen">
      <Col className="justify-center items-center flex-col space-y-10 lg:w-3/12 md:6/12 sm:w-6/12">
        <Col className="flex justify-center items-center">
          <Logo />
        </Col>
        <Col className="flex justify-center items-center text-white font-bold text-6xl">
          <span className="px-1">4</span>
          <img src={img404} />
          <span className="px-1">4</span>
        </Col>
        <Col className="flex justify-center items-center">
          <Label className="text-white">
            Opps .. there seems to be a problem
          </Label>
        </Col>

        <Col>
          <TailwindButton
            className="rounded-full w-full"
            onClick={() => redirect(history, PUBLIC_BASE_URL)}
          >
            Take Me to Safety
          </TailwindButton>
        </Col>
      </Col>
    </div>
  );
};

export default ProblemModel;
