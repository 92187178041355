import React from "react";
import { LeftNavbar } from "../components/LeftNavbar/LeftNavbar";
import { useOrderContext } from "../contexts/OrderContext";
import { useHistory } from "react-router-dom";

import { useAuth } from "../contexts/authContext";
import { useRedirectUnkownPath } from "../adapters/redirect";
import { Menu, Close } from "@material-ui/icons";
import { Label } from "../components/ui";
import Logo from "../components/Logo/Logo";
const PrivateLayout = ({ children }) => {
  const history = useHistory();
  const { setHistory } = useOrderContext();
  const { isAuthenticated } = useAuth();
  useRedirectUnkownPath();
  React.useEffect(() => {
    setHistory(history);
    setTimeout(() => {
      if (!isAuthenticated) {
        history.push("/login");
      }
    }, 0);
  }, []);

  const [showMenu, setShowMenu] = React.useState(false);
  const menuIcon = showMenu ? <Close /> : <Menu />;
  return (
    <div className="flex flex-row h-screen">
      <LeftNavbar className="hidden md:block" />
      <main className="w-full h-screen pb-16 md:p-0">
        <div className="overflow-x-hidden h-full p-4 flex justify-right">
          {children}
        </div>
      </main>
      <div
        className={`${
          showMenu ? "h-full" : "h-16"
        } bottom-0 w-full bg-BlackGray md:hidden fixed space-x-2 text-white px-4 pt-4 duration-500 transition-all`}
      >
        <div className="flex justify-between items-start ">
          <Label color="white" size="lg">
            <Logo />
          </Label>
          <div onClick={() => setShowMenu(!showMenu)}>{menuIcon}</div>
        </div>

        {showMenu && (
          <LeftNavbar
            isMobile
            className={"w-full"}
            callback={() => {
              setShowMenu(!showMenu);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PrivateLayout;
