import React, { useState } from "react";
import ConfirmCancelSubscription from "../components/Modal/ConfirmCancelSubscription";

const withConfirmationCancelSub = (Component: any) => (props: any) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ title: "", subTitle: "" });
  const [callBack, setCallback] = useState<any>(null);

  function onConfirm() {
    callBack();
    setOpen(false);
  }
  function showModal({
    title,
    subTitle,
    confirmFunction,
  }: {
    title: string;
    subTitle: string;
    confirmFunction: () => void;
  }) {
    setOpen(true);
    setState({
      title,
      subTitle,
    });
    setCallback(() => confirmFunction);
  }
  return (
    <React.Fragment>
      <Component className="bg-black" {...props} showConfirmModal={showModal} />
      <ConfirmCancelSubscription
        show={open}
        onConfirm={() => {
          onConfirm();
        }}
        onToggle={() => {
          setOpen(false);
        }}
      />
    </React.Fragment>
  );
};
export default withConfirmationCancelSub;
