import Check from "@material-ui/icons/Check";
import React from 'react';
import TailwindButton from '../../components/Button/TailwindButton';
import { CounterLocation } from '../../components/CounterLocation/CounterLocation';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from "../../components/Grid/GridItem";

const ListItem = ({ label }) => (
  <GridItem xs={12}>
    <p className="list-item">
      <Check className="text-primary" />
      <span className="ml-1">{label}</span>
    </p>
  </GridItem>
);

export const PriceCard = (props) => {
  const { title, description, price, btnText, currency, items, onHandleQuantity, onClick, count } = props;
  return (
    <div className="w-12/12 lg:mx-8 xs:mx-8 mb-8">
      <div className="flex flex-col bg-BlackGray p-8 rounded-lg w-12/12 h-[652px]">
        <div>
          <p className="card-title">{title}</p>
          <p className="text-white font-bold text-4xl"> {currency}{!!price ? price.toFixed(2) + ' + VAT' : 'Custom'} </p>
          <p className="text">
            {description}
          </p>
          <hr className="text-primary my-5" />
          <p className="title-of-the-list">What do i get ?</p>
          <GridContainer>
            {items.map((label) => (
              <ListItem label={label} key={label} />
            ))}
            <GridItem xs={12}>
              <GridContainer style={{ width: "287px" }}>
                <GridItem xs={7}>
                  <p className="list-item">{onHandleQuantity ? 'Locations' : <br />}</p>
                </GridItem>
                <GridItem
                  xs={5}
                  style={{
                    textAlign: "left",
                    marginTop: "15px",
                    paddingLeft: "0px",
                  }}
                >
                  {onHandleQuantity && <CounterLocation
                    handleQuantity={onHandleQuantity}
                    count={count}
                  />}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <div className="w-full mt-4 mt-auto">
          <TailwindButton
            className="rounded-full w-full mt-5"
            onClick={onClick}>
            {btnText}
          </TailwindButton>
        </div>
      </div>
    </div>
  )
}