import React from "react";
import Logo from "../../components/Logo/Logo";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import TailwindButton from "../../components/Button/TailwindButton";
import Input from "../../components/Input";
export default function Register() {
  const history = useHistory();
  const { register, isAuthenticated } = useAuth();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="flex flex-col justify-center items-center">
          <div>
            <Logo />
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "* Email address is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "* Invalid email address";
              }
              if (!values?.password) {
                errors.password = "* Password is required.";
              }
              if (!values?.confirmPassword) {
                errors.confirmPassword = "* Confirm password is required.";
              } else if (values?.password !== values?.confirmPassword) {
                errors.confirmPassword = "* Password does not matched";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              register(
                values,
                history,
                //onSuccess
                () => setSubmitting(false),
                //onError
                (error) => {
                  setSubmitting(false);
                  setError(error.message);
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              /* and other goodies */
            }) => (
              <div>
                <div className="mt-4">
                  <span className="text-white text-2xl">
                    Let's create an account
                  </span>
                </div>

                <div className="mt-5">
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    maxLength={120}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email address"
                    variant="outline"
                  />
                  <Input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    maxLength={120}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password"
                    variant="outline"
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    maxLength={120}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    placeholder="Confirm Password"
                    variant="outline"
                  />
                  <div className="w-full text-red-500 mt-2 text-xs">
                    <p>{errors.email && touched.email && errors.email}</p>
                    <p>
                      {errors.password && touched.password && errors.password}
                    </p>
                    <p>
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </p>
                    {error && <div className="text-red-500 "> {error} </div>}
                  </div>

                  <div className="mt-2 flex flex-col pt-4">
                    <TailwindButton
                      onClick={handleSubmit}
                      className="w-[330px] rounded-full"
                      loading={isSubmitting}
                    >
                      Register
                    </TailwindButton>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
