import React from 'react';
import ParagraphPage from './ParagraphPage';

export function TermsAndConditionPage(props: any) {
  return (
    <ParagraphPage
      title="Terms and conditions"
      paragraph={`
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
      `}
    />
  );
}

export function PrivacyPolicyPage(props: any) {
  return (
    <ParagraphPage
      title="Privacy Policy"
      paragraph={`
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
        Veniam sunt culpa reprehenderit eu eiusmod est sit do commodo commodo Lorem nisi fugiat est. Excepteur pariatur aliqua esse sunt aliquip deserunt id. Amet consectetur ullamco velit deserunt ipsum ad ex sit aute velit pariatur nulla nulla. Quis commodo cupidatat dolore nostrud minim tempor ut occaecat cillum enim cupidatat qui. Pariatur mollit nulla elit enim elit.
      `}
    />
  );
}
