import { FC } from "react";

import visa from "./../../assets/cards/visa.png";
import discover from "./../../assets/cards/discover.png";
import maestro from "./../../assets/cards/maestro.png";
import mastercard from "./../../assets/cards/mastercard.png";
import paypal from "./../../assets/cards/paypal.png";

export enum PAYMENT_MODE {
  STRIPE = "STRIPE",
  PAYPAL = "PAYPAL",
}

export const PaymentMode: FC<{
  label: string;
  value: any;
  onChange: any;
  onBlur: any;
  loading: boolean;
  type: PAYMENT_MODE;
}> = ({ label, value, type, onBlur, onChange, loading }) => {
  const checked = type === value;

  return (
    <label
      htmlFor={type}
      className="bg-primary h-12 px-4 py-2 w-full max-w-[400px] placeholder-black border-black border cursor-pointer rounded-full"
    >
      <div className="flex justify-between items-center w-full cursor-pointer">
        <div className="uppercase cursor-pointer">
          <label className="flex items-center cursor-pointer">
            <input
              id={type}
              type="radio"
              name="method"
              value={type}
              checked={checked}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              hidden
            />
            <span
              className={`flex justify-center items-center h-4 w-4 rounded-full border-2 border-black border-t-2 "
              ${checked && loading ? "animate-spin border-dashed" : "border"}`}
            >
              {checked && <span className="h-2 w-2 bg-BlackGray rounded-full" />}
            </span>
            <span className="ml-2 text-sm font-bold text-black pr-4">
              {label}
            </span>
          </label>
        </div>
        <div className="flex flex-row space-x-1">
          {type === PAYMENT_MODE.STRIPE && (
            <>
              <img src={visa} alt="visa" />
              <img src={discover} alt="discover" />
              <img src={maestro} alt="maestro" />
              <img src={mastercard} alt="mastercard" />
            </>
          )}
          {type === PAYMENT_MODE.PAYPAL && (
            <>
              <img width={100} src={paypal} alt="paypal" />
            </>
          )}
        </div>
      </div>
    </label>
  );
};
