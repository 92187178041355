import { API_ENDPOINT, STRIPE_PUBLIC_KEY } from '../.env'

const STRIPE_ENDPOINT = "https://api.stripe.com/v1/tokens";

const ENDPOINTS = {
  AUTH: "xauth/signin",
  REGISTER: "users/register",
  FORGOT: "users/resetPassword",
  SONGS: "songs",
  GENRES: "genres",
  ARTISTS: "artists",
  MOODS: "moods",
};
export { API_ENDPOINT, ENDPOINTS, STRIPE_ENDPOINT, STRIPE_PUBLIC_KEY };
