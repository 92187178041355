import React from "react";
import classNames from "classnames";
interface Props {
  children: React.ReactNode;
  className?: string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
}
export default function Col({ children, className, sm, md, lg }: Props) {
  let customClassName: string = "";
  if (sm) {
    customClassName += `sm:w-${sm}/12 `;
  }
  if (md) {
    customClassName += `md:w-${md}/12 `;
  }
  if (lg) {
    customClassName += `lg:w-${lg}/12 `;
  }

  return (
    <div className={classNames(customClassName, className)}>{children}</div>
  );
}
