import { Formik } from "formik";
import { Button } from "../../components/Button/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import USTextField from "../../components/TextField/USTextField";
import SelectField from "../../components/SelectField";
import "./StyleStepper.css";
import { countries } from "../../utils/countries";

export default function OrderStepTwo({
  initialValues,
  onSubmit,
  onChangeInput,
}) {
  const {
    companyName = "",
    address = "",
    city = "",
    zipCode = "",
    country = "",
    type = "",
    uidVAT = "",
  } = initialValues ?? {};

  return (
    <>
      <Formik
        initialValues={{
          companyName: companyName,
          address: address,
          city: city,
          zipCode: zipCode,
          country: country,
          type: type,
          uidVAT: uidVAT,
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          let errors = {};
          if (
            values.type === "business" &&
            (!values.companyName || values.companyName.length === 0)
          ) {
            errors.companyName = "* Company name is required";
          }
          if (!values.address || values.address.length === 0) {
            errors.address = "* Address is required";
          }
          if (!values.city || values.city.length === 0) {
            errors.city = "* City is required";
          }
          if (
            !values.zipCode ||
            values.zipCode.length === 0 ||
            !/^\d+$/i.test(values.zipCode)
          ) {
            errors.zipCode = "* Zip code is required";
          }
          if (!values.country || values.country.length === 0) {
            errors.country = "* Country is required";
          }

          if (!values.country || values.country.length === 0) {
            errors.country = "* Country is required";
          }
          if (type === "business" && country !== "AT") {
            if (!values.uidVAT) {
              errors.uidVAT = "* UID/VAT is required";
            }
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,

          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className="flex w-full flex-wrap justify-center items-center">
            {values.type === "business" && (
              <div className="w-[340px]">
                <USTextField
                  type="text"
                  label="Company name"
                  name="companyName"
                  value={values.companyName}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                ></USTextField>
                {touched.companyName && (
                  <div className="text-red-500"> {errors.companyName} </div>
                )}
              </div>
            )}
            {values.type !== "" && (
              <div className="w-[340px]">
                <USTextField
                  type="text"
                  label="Address"
                  name="address"
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                ></USTextField>
                {touched.address && (
                  <div className="text-red-500"> {errors.address} </div>
                )}
              </div>
            )}
            {values.type !== "" && (
              <div className="w-[340px]">
                <USTextField
                  label="City"
                  name="city"
                  type="text"
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                ></USTextField>
                {touched.city && (
                  <div className="text-red-500"> {errors.city} </div>
                )}
              </div>
            )}
            {values.type !== "" && (
              <div className="w-[340px]">
                <USTextField
                  label="Zip code / PLZ"
                  name="zipCode"
                  type="text"
                  value={values.zipCode}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                ></USTextField>
                {touched.zipCode && (
                  <div className="text-red-500"> {errors.zipCode} </div>
                )}
              </div>
            )}
            {values.type !== "" && (
              <div className="w-[340px]">
                <SelectField
                  name="country"
                  value={values.country}
                  options={countries}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                />
                {touched.country && (
                  <div className="text-red-500"> {errors.country} </div>
                )}
              </div>
            )}
            {type === "business" && country !== "AT" && (
              <div className="w-[340px]">
                <USTextField
                  type="text"
                  label="UID/VAT"
                  name="uidVAT"
                  value={values.uidVAT}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                ></USTextField>
                {touched.uidVAT && (
                  <div className="text-red-500"> {errors.uidVAT} </div>
                )}
              </div>
            )}
            <div>
              <Button
                type="button"
                width="327px"
                onClick={handleSubmit}
                name={"Next"}
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
