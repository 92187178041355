import React from "react";
import { Col, Row, Label } from "../ui/index";
import moment from "moment";
import invoice from "../../repositories/invoice";
import withConfirmationCancelSub from "../../hoc/withConfirmationSubscription";
import TailwindButton from "../Button/TailwindButton";
export interface TOrderContext {
  count?: number;
  total?: number;
  input?: any;
  unitPrice?: number;
  setHistory?: any;
  onChangeInput?: any;
  handleQuantity?: any;
  submitPaymentWithStripe?: any;
}
export interface SubscriptionItemProps {
  id: number;
  price: number;
  startDate: string;
  endDate: string;
  subscriptionStatus: string;
  numberAuthorizedLocations: number;
  description?: string;
  VAT?: string
}
export const SubscriptionItem: React.FC<{
  data: SubscriptionItemProps;
  cancelSubscription: (item: any) => void;
}> = ({
  data,
  cancelSubscription,
}: {
  data: SubscriptionItemProps;
  cancelSubscription: (item: any) => void;
}) => {
  const {
    endDate,
    numberAuthorizedLocations,
    price,
    startDate,
    subscriptionStatus,
    description = "This subscription is valid until",
    VAT = "",
  } = data;

  const isCanceled = !["ACTIVE", "FREE_TRIAL", "IN PROGRESS"].includes(
    subscriptionStatus
  );
  const classColor = isCanceled ? "text-gray-500" : "text-primary";

  const getSubscriptionName = (sub?: string) => {
    if (!sub || sub === "") return "N/A";
    switch (sub) {
      case "ACTIVE":
        return "Active";
      case "CANCELED":
        return "Canceled";
      case "FREE_TRIAL":
        return "Free trial";
      case "EXPIRED":
        return "Expired";
      default:
        return sub;
    }
  };

  // format moment(endDate) as "MMM DD, YYYY"
  const formattedEndDate = moment(endDate).format("MMM DD, YYYY");
  return (
    <Col sm={12} md={12} className="mb-4">
      <Col className="bg-BlackGray p-4 rounded-lg lg:w-full">
        <Row className=" items-start">
          <Col className="space-y-6 lg:w-6/12 md:w-full sm:w-full">
            <Label className={classColor} font="semibold">
              The Essential
            </Label>
            <Row className="">
              <Label
                className={isCanceled ? "text-gray-500" : "text-white"}
                size="3xl"
                font="semibold"
              >
                {"€" + price.toFixed(2)}
                {VAT}
              </Label>

              <Col className="ml-5 mt-4">
                <Label
                  size="sm"
                  className={isCanceled ? "text-gray-500" : "text-gray-400"}
                >
                  Location/Month
                </Label>
              </Col>
            </Row>
          </Col>
          <Col className="space-y-5" lg={6} md={6}>
            <Label
              className={isCanceled ? "text-gray-500" : "text-primary"}
              font="semibold"
            >
              {getSubscriptionName(subscriptionStatus)}
            </Label>
            <p className={isCanceled ? "text-gray-500" : "text-xs text-white"}>
              {description} {formattedEndDate}
            </p>
            <Row className="items-center justify-between space-x-5">
              <div className="flex items-center space-x-5">
                <Col sm={2}>
                  <div
                    className={
                      isCanceled
                        ? "text-gray-500"
                        : "text-black" + " btn-minus pt-1 cursor-pointer"
                    }
                  >
                    <p className="location-number">
                      {numberAuthorizedLocations}
                    </p>
                  </div>
                </Col>
                <Col sm={8}>
                  <p
                    className={
                      isCanceled ? "text-gray-500" : "text-white" + " location"
                    }
                  >
                    Locations
                  </p>
                </Col>
              </div>

              {subscriptionStatus === "ACTIVE" && (
                <div>
                  <TailwindButton
                    variant="custom"
                    onClick={() => cancelSubscription(data)}
                  >
                    <span className="text-white">Cancel subscription</span>
                  </TailwindButton>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
function HomeInvoice(props: any) {
  const [data, setData] = React.useState<any>(null);
  const [update, setUpdate] = React.useState(false);
  function loadData() {
    invoice.getSubscriptions().then((httpResponse) => {
      const result = httpResponse?.data.sort((a: any, b: any) => {
        return a.subscriptionStatus < b.subscriptionStatus
          ? -1
          : a.subscriptionStatus > b.subscriptionStatus
          ? 1
          : 0;
      });
      setData(result);
    });
  }
  function cancelSubscription(item: any) {
    props.showConfirmModal({
      title: "Are you sure you want to cancel subscriptions",
      confirmFunction: async () => {
        invoice.cancelSubscription(item?.id).then((httpResponse) => {
          loadData();
        });
        setUpdate(!update);
      },
    });
  }

  React.useEffect(() => {
    loadData();
  }, [update]);

  if (!data?.length) {
    return null;
  }

  return data?.map((item: SubscriptionItemProps, i: number) => (
    <SubscriptionItem
      data={item}
      cancelSubscription={cancelSubscription}
      key={"sub-" + i}
    />
  ));
}
export default withConfirmationCancelSub(HomeInvoice);
