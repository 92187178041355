import TailwindButton from "../Button/TailwindButton";

interface Props {
  show: boolean;
  onToggle: () => void;
  onConfirm: () => void;
}

export default function ConfirmCancelSubscription({
  show,
  onToggle,
  onConfirm,
}: Props) {
  return show ? (
    <div
      onClick={onToggle}
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl ">
        <div className="relative rounded-3xl flex flex-col bg-black outline-none focus:outline-none border border-white border-solid shadow-[0_0_30px_-15px_rgba(0,0,0,1)] shadow-primary">
          <div className="relative p-8 flex-auto w-full">
            <div className="bg-black">
              <div>
                <p className="text-3xl text-white text-center"> Goodbyes are never easy, we <br/> will always be here for you</p>
              </div>
              <div className="pt-6">
                <p className="text-md text-white text-center"> 
                  Your benefit will continue until the end of<br/>your current subscription month, after<br/>which your card will not be charged
                </p>
              </div>
              <div className="flex justify-center items-center space-x-5 mt-10">
                <TailwindButton onClick={onConfirm} className="rounded-full">
                  <span>Cancel Subscription</span>
                </TailwindButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : <></>;
}
