import equalizer from "../../styles/img/equalizer.gif";

const ActivatingSubscriptionModel = () => {
  return (
    <div className="flex flex-col justfiy-center items-center">
      <img src={equalizer} alt="ilioamusic"/>
      <p className="text-2xl font-semibold text-white">
        Activating your subsciption
      </p>
      <small className="text-white text-center">
        This might take a few seconds
      </small>
    </div>
  );
};

export default ActivatingSubscriptionModel;
