import React from "react";
import "../../styles/PublicLayout.css";

const Logo = () => {
  return (
    <div>
      <span className="text-white text-3xl">ilioa</span>
      <span className="text-primary text-3xl">music</span>
    </div>
  );
};

export default Logo;
