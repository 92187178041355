import React from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-hot-toast";
import Invoice from "../../repositories/invoice";

import {
  PAYPAL_CLIENT_ID,
  PAYPAL_PLAN_ID_VAT,
  PAYPAL_PLAN_ID_NO_VAT,
} from "../../.env";
import { useOrderContext } from "../../contexts/OrderContext";
import { useHistory } from "react-router";
interface Props {
  hasVAT: boolean;
  businessType: boolean;
  vatID: boolean;
  quantity: number;
  onSubmitSubscribe: (callback: () => Promise<void>) => void;
}

export const PayPalButton: React.FC<Props> = (props) => {
  const { quantity = 1, hasVAT, onSubmitSubscribe } = props;
  const { input } = useOrderContext();

  const history = useHistory();

  const createSubscription = async (
    data: any,
    actions: any
  ): Promise<string> => {
    toast.loading("Creating subscription...", { id: "create-subscription" });
    const result = await actions.subscription.create({
      plan_id: hasVAT ? PAYPAL_PLAN_ID_VAT : PAYPAL_PLAN_ID_NO_VAT,
      quantity,
    });
    return result;
  };
  const onApprove = async (data: any, actions: any): Promise<void> => {
    toast.loading("Creating subscription...", { id: "create-subscription" });
    onSubmitSubscribe(async () => {
      await Invoice.subscriptions({
        locationsNumber: quantity,
        paymentMethod: "PAYPAL",
        paymentToken: data.facilitatorAccessToken,
        paypalSubscriptionId: data.subscriptionID,
        vatID: input.uidVAT,
        country: input.country,
        businessType: input.type,
      });
      toast.success("Subscription created successfully");
      history.push({
        pathname: "/success",
      });
    });
    toast.success("Payment successfully approved!", {
      id: "create-subscription",
    });
  };
  const onCancel = (data: any) => {
    toast("You have cancelled the payment", { id: "create-subscription" });
  };
  const onError = (err: any) => {
    toast.error("Something went wrong", { id: "create-subscription" });
  };
  return (
    <PayPalScriptProvider
      options={{
        "client-id": PAYPAL_CLIENT_ID,
        currency: "EUR",
        intent: "subscription",
        vault: true,
      }}
    >
      <PayPalButtons
        style={{ layout: "horizontal", shape: "pill", color: "white" }}
        className="w-[327px] h-[64px] pt-6"
        createSubscription={createSubscription}
        onApprove={onApprove}
        onError={onError}
        onCancel={onCancel}
      />
    </PayPalScriptProvider>
  );
};
