import { Route } from "react-router-dom";
import { Pricing, Order, ContactUs, PrivacyPolicyPage, TermsAndConditionPage } from "../pages/index";
import {
  ORDER_SUMMARY_URL,
  PUBLIC_BASE_URL,
  CONTACT_US_URL,
  FAQ,
  TERMS_AND_CONDITION_URL,
  PRIVACY_POLICY_URL,
  PAYMENT_OK,
} from "./urls";
import PrivateLayout from "./PrivateLayout";
import FaqPage from "../pages/Faq";
import { OrderSuccess } from "../pages/SuccessPayment/OrderSuccess";

const routes = {
  PrivateLayout: {
    component: PrivateLayout,
    children: [
      {
        path: PUBLIC_BASE_URL,
        exact: true,
        component: Pricing,
        routeComponent: Route,
      },
      {
        path: `${ORDER_SUMMARY_URL}`,
        exact: true,
        component: Order,
        routeComponent: Route,
      },
      {
        path: `${TERMS_AND_CONDITION_URL}`,
        exact: true,
        component: TermsAndConditionPage,
        routeComponent: Route,
      },
      {
        path: `${PRIVACY_POLICY_URL}`,
        exact: true,
        component: PrivacyPolicyPage,
        routeComponent: Route,
      },
      {
        path: `${CONTACT_US_URL}`,
        exact: true,
        component: ContactUs,
        routeComponent: Route,
      },
      {
        path: `${FAQ}`,
        exact: true,
        component: FaqPage,
        routeComponent: Route,
      },
      {
        path: PAYMENT_OK,
        exact: true,
        component: OrderSuccess,
        routeComponent: Route,
      }
    ],
  },
};

export default routes;
