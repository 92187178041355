import cn from "classnames";
import React, { SelectHTMLAttributes } from "react";

export interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  inputClassName?: string;
  label?: string;
  note?: string;
  error?: string;
  type?: string;
  options: Array<{ label: string; value: string }>;
}

const classes = {
  root: "rounded-full outline-none border-0 w-[327px] px-4 h-12 flex items-center w-full appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0",
  outline: "bg-BlackGray border border-border-base focus:border-accent text-white",
  shadow: "focus:shadow",
};
const Select = React.forwardRef<HTMLSelectElement, Props>(
  (
    {
      className,
      label,
      note,
      name,
      value,
      error,
      children,
      options,
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      classes.root,
      classes.outline,
      classes.shadow,
      value === "" ? "text-gray-400" : "",
      inputClassName
    );

    return (
      <div className={className}>
        <label
          htmlFor={name}
          className="block text-white font-semibold text-sm leading-none mb-3"
        >
          {label}
        </label>
        <select
          id={name}
          name={name}
          value={value}
          className={rootClassName}
          aria-invalid={error ? "true" : "false"}
          {...rest}
        >
          {
            options.map(({ label, value }) => (
              <option key={value} value={value} className={value === "" ? "text-gray-400" : ""}>{label}</option>
            ))
          }
        </select>
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {!!error && (
          <p className="my-2 text-xs text-start text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

export default Select;