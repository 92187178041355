import PropTypes from "prop-types";
import "./Pricing.css";
import { useHistory } from "react-router";
import { redirect } from "../../adapters/redirect";
import { CONTACT_US_URL, ORDER_SUMMARY_URL } from "../../routing/urls";
import { useOrderContext } from "../../contexts/OrderContext";
import HomeInvoice from "../../components/InvoiceContainer/HomeInvoice";
import { PriceCard } from "./PriceCard";

const EssentialItems = [
  "Legal for business use",
  "One minute setup time",
  "Works with all sound systems",
  "No ads. ExpGridItemcit content filtered",
  "Music control via app",
  "Fresh new songs daily",
];
const ProfessionalItems = [
  "Everything in Essential plan",
  "Import of existing playlists",
  "Centralized control of all locations",
  "Dedicated account manager",
  "Priority support",
  "Fresh new songs daily",
];


const Pricing = () => {
  const history = useHistory();
  const title = "Pricing & Subscription";
  const { total, count, handleQuantity } = useOrderContext();
  return (
    <div className="my-2">
      <div className="mx-4 h-16">
        <p className="title">
          {title}
        </p>
      </div>

      <div className="grid grid-cols-1 gap-1">
        <div className="my-8 sm:m-8 xs:m-8 md:m-8">
          <div className="flex flex-col rounded-lg w-12/12">
            <HomeInvoice />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-1 sm:m-8 xs:m-8 md:m-8 lg:m-0 gap-10">
        <PriceCard
          title="Essential"
          description="The most popular plan. Perfect for small businesses."
          price={total}
          count={count}
          currency="€"
          items={EssentialItems}
          onHandleQuantity={handleQuantity}
          btnText="Buy Now"
          onClick={() => redirect(history, ORDER_SUMMARY_URL, {})}
        />
        <PriceCard
          title="The Professional"
          description="This subscription is best for companies managing multiple locations."
          items={ProfessionalItems}
          btnText="Contact Us"
          onClick={() => redirect(history, CONTACT_US_URL, {})}
        />
      </div>
    </div>
  );
};

export default Pricing;

Pricing.propTypes = {
  data: PropTypes.object,
};
