import { InfoRounded } from "@material-ui/icons";

import { Col } from "../../components/ui";
import TailwindButton from "../../components/Button/TailwindButton";
import InvoiceRepository from "../../repositories/invoice";
import { TextareaAutosize } from "@material-ui/core";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";

export default function ContactUs() {
  const sendMail = async (values) => {
    try {
      await InvoiceRepository.contactUs({ body: values.message });
      toast.warn("Message sent successfully", {
        icon: <InfoRounded className="text-primary" />,
        className: "bg-red-500 text-white",
      });
      return {};
    } catch (err) {
      return {
        error: "Failed to send message",
      };
    }
  };

  // Yup validation
  const validateSchema = yup.object().shape({
    message: yup
      .string()
      .min(50, "Message must be at least 50 characters long")
      .required("Message is required"),
  });

  return (
    <div className="w-full">
      <Col className="m-2">
        <div className="title pb-16">Contact us</div>
        <div>
          <Formik
            initialValues={{
              message: "",
            }}
            validateOnBlur={true}
            validationSchema={validateSchema}
            onSubmit={async (values, { setFieldValue, setFieldError }) => {
              const { error } = await sendMail(values);
              if (error) {
                setFieldError("message", error);
                return;
              }
              setFieldValue("message", "");
            }}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleBlur,
              handleSubmit,
              submitForm,
              isSubmitting,
              dirty,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Col className="mt-4 w-full">
                  <TextareaAutosize
                    id="message"
                    name="message"
                    className="border border-gray-600 bg-black rounded p-5 text-white w-full"
                    placeholder="Please let us know how can we help"
                    minRows={10}
                    value={values.message}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  ></TextareaAutosize>
                  <Col className="flex justify-between text-primary w-full">
                    <span>{touched.message && dirty && errors.message}</span>
                    <span className="text-gray-600">
                      count:{values.message.length}
                    </span>
                  </Col>
                  <Col className="flex justify-end items-end mt-5">
                    <TailwindButton
                      onClick={submitForm}
                      className="rounded-full w-full lg:w-1/3"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Send
                    </TailwindButton>
                  </Col>
                </Col>
              </form>
            )}
          </Formik>
        </div>
      </Col>
    </div>
  );
}
