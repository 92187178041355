import { Button } from "../Button/Button";
import TailwindButton from "../Button/TailwindButton";
interface Props {
  tryAgain?: () => void;
}
const OpsConatiner = ({ tryAgain }: Props) => {
  return (
    <div className="flex  justfiy-center items-center flex-col space-y-5">
      <p className="text-4xl font-semibold text-red-800">OoOPS !</p>
      <small className="text-white text-center text-lg font-semibold">
        There seems to be a problem
      </small>
      <div className="">
        <small className="text-gray-400 text-sm text-center w-45 font-semibold truncate">
          There was a problem processing your data please try again later
        </small>
      </div>
      <div className="mt-10">
        <TailwindButton className="rounded-full h-10" onClick={tryAgain}>
          Let's try again
        </TailwindButton>
      </div>
    </div>
  );
};

export default OpsConatiner;
