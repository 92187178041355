import React from "react";
import Stepper from "../../components/Stepper/Stepper";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useOrderContext } from "../../contexts/OrderContext";
import { PaymentMode, PAYMENT_MODE } from "./PaymentMethod";
import { SubscriptionItem } from "../../components/InvoiceContainer/HomeInvoice";
import TailwindButton from "../../components/Button/TailwindButton";
import toast from "react-hot-toast";
import { PayPalButton } from "../../components/Button/PayPalButton";
import OrderStepZero from "./OrderStepZero";
import OrderStepOne from "./OrderStepOne";
import OrderStepThree from "./OrderStepThree";
import OrderStepTwo from "./orderStepTwo";

export default function Order({ title = "Pricing & Subscription" }) {
  const history = useHistory();
  const {
    total,
    submitPaymentWithStripe,
    submitPaymentWithPaypal,
    count,
    onChangeInput,
    input,
    hasVAT,
  } = useOrderContext();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [reachedStep, setReachedStep] = React.useState(0);
  const [paymentMode, setPaymentMode] = React.useState(undefined);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (currentStep > reachedStep) {
      setReachedStep(currentStep);
    }
  }, [currentStep]);

  const handleBack = () => {
    if (currentStep === 0) return;
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (currentStep <= 3)
      setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  };

  const stepsTitle = [
    "payment method",
    ...(paymentMode === undefined
      ? []
      : [
          ...(paymentMode !== PAYMENT_MODE.STRIPE
            ? ["business type", "account details", "billing details"]
            : []),
          "confirmation",
        ]),
  ];

  return (
    <div className="h-screen w-full">
      <div className="flex my-5 h-16 items-center">
        <div className="flex items-center text-white font-sans">
          <span onClick={() => history.replace("/")} className="cursor-pointer">
            <ArrowBack className="mr-2" />
          </span>
          <span className="text-xl font-bold">{title}</span>
        </div>
      </div>

      <div className="w-full">
        <SubscriptionItem
          data={{
            id: -1,
            price: total,
            endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
            startDate: new Date(),
            numberAuthorizedLocations: count,
            subscriptionStatus: "IN PROGRESS",
            description: "This subscription will be valid until",
            VAT: <span className="text-xs font-normal"> + VAT (20%)</span>,
          }}
        />
      </div>
      <Stepper
        stepsCount={stepsTitle.length}
        setActiveStep={setCurrentStep}
        activeStep={currentStep}
      />

      <div className="w-full text-white uppercase space-x-4 flex justify-center items-center overflow-x-auto">
        {stepsTitle.map((title, index) => {
          const isActive =
            currentStep === index ||
            (paymentMode === PAYMENT_MODE.STRIPE) & (currentStep > 0);
          return (
            <>
              <span
                onClick={
                  reachedStep >= index ? () => setCurrentStep(index) : null
                }
                className={`cursor-pointer ${isActive ? "" : "text-gray-500"}`}
              >
                {title}
              </span>
              {index !== stepsTitle.length - 1 && <span> - </span>}
            </>
          );
        })}
      </div>

      {currentStep === 0 && (
        <div className="flex flex-col justify-center items-center mt-24">
          <PaymentMode
            label="credit card"
            type={PAYMENT_MODE.STRIPE}
            value={paymentMode}
            loading={false}
            onChange={(value) => {
              setPaymentMode(value);
              if (currentStep === 0) setCurrentStep(4);
            }}
          />
        </div>
      )}

      {currentStep === 1 && (
        <div className="flex flex-col justify-center items-center mt-24">
          <OrderStepZero
            initialValues={{
              type: input.type,
            }}
            onSubmit={() => {
              handleNext();
            }}
            onChangeInput={(value) => {
              onChangeInput(value);
            }}
          />
        </div>
      )}

      {currentStep === 2 && (
        <div className="flex flex-col justify-center items-center mt-10">
          <OrderStepOne
            initialValues={{
              firstName: input.firstName,
              lastName: input.lastName,
              phone: input.phone,
            }}
            onSubmit={() => {
              handleNext();
            }}
            onChangeInput={(value) => {
              onChangeInput(value);
            }}
          />
        </div>
      )}

      {currentStep === 3 && (
        <div className="flex flex-col justify-center items-center mt-10">
          <OrderStepTwo
            initialValues={{
              companyName: input.companyName,
              address: input.address,
              city: input.city,
              zipCode: input.zipCode,
              country: input.country,
              type: input.type,
            }}
            onSubmit={() => {
              handleNext();
            }}
            onChangeInput={(value) => {
              onChangeInput(value);
            }}
          />
        </div>
      )}

      {currentStep === 4 && paymentMode === PAYMENT_MODE.STRIPE && (
        <div className="flex flex-col justify-center items-center mt-24">
          <TailwindButton
            type="button"
            className="rounded-full w-[300px]"
            onClick={async () => {
              // wait for 500 ms to simulate a loading state
              setLoading(true);
              toast.loading("Processing payment...", { id: "payment" });
              await new Promise((resolve) => setTimeout(resolve, 3000));
              await submitPaymentWithStripe();
              setLoading(false);
            }}
            loading={loading}
          >
            Confirm
          </TailwindButton>
        </div>
      )}

      {currentStep === 4 && paymentMode === PAYMENT_MODE.PAYPAL && (
        <div className="flex flex-col justify-center items-center mt-24">
          <PayPalButton
            quantity={count}
            hasVAT={hasVAT}
            onSubmitSubscribe={submitPaymentWithPaypal}
          />
        </div>
      )}
    </div>
  );
}
