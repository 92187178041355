import { createContext, useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import ModalCentred from "../components/Modal/ModalCentred";
import ActivatingSubscription from "../components/ModalContainer/ActivatingSubscription";
import OpsConatiner from "../components/ModalContainer/OpsContainer";
import InvoiceService from "../repositories/invoice";
import PaymentService from "../repositories/payment";

const initalValue = {
  count: 1,
  total: 29.99,
  input: {
    firstName: "",
    lastName: "",
    phone: "",
    cardNumber: "",
    ccv: "",
    cardExpiryDate: "",
    companyName: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    uidVAT: "",
    type: "",
  },
};
export const OrderContext = createContext(initalValue);
const OrderProvider = ({ children }) => {
  const [modalWating, setModalWaiting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [history, setHistory] = useState(null);
  /**
   * toggle modals
   */
  const toggleModalWaiting = () => {
    setModalWaiting((prev) => !prev);
  };
  const toggleModalError = () => {
    setModalError(!modalError);
  };
  const [count, setCount] = useState(1);
  const unitPrice = 29.99;
  const [total, setTotal] = useState(unitPrice);
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    cardNumber: "",
    ccv: "",
    cardExpiryDate: "",
    companyName: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    uidVAT: "",
    type: "",
  });

  const [hasVAT, setHasVAT] = useState(false);

  useEffect(() => {
    setHasVAT(input.country === "AT" || input?.type === "private");
  }, [input.country, input.type]);

  function handleQuantity(qt) {
    if (qt === 1 && count === 10) return null;
    if (qt === -1 && count === 1) return null;
    setCount((prev) => prev + qt);
    setTotal((prev) => prev + unitPrice * qt);
  }

  function onChangeInput({ target: { name, value } }) {
    setInput({ ...input, [name]: value });
  }

  const submitPaymentWithPaypal = async (callback) => {
    try {
      toggleModalWaiting();
      await callback();
    } catch (err) {
      toggleModalError();
    } finally {
      toggleModalWaiting();
    }
  };

  async function submitPaymentWithStripe() {
    // toggleModalWaiting();
    try {
      const data = await InvoiceService.stripe_session(count);
      toast.success("Redirecting to payment...", { id: "payment" });
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window.open(data.data.url, "_self");
      // toggleModalWaiting();
    } catch (err) {
      // toggleModalWaiting();
      toggleModalError();
      toast.error("Error processing payment");
    }
  }

  return (
    <OrderContext.Provider
      value={{
        count,
        total,
        input,
        unitPrice,
        hasVAT,
        setHistory,
        onChangeInput,
        handleQuantity,
        submitPaymentWithStripe,
        submitPaymentWithPaypal,
      }}
    >
      {children}
      {/** modal waiting */}
      <ModalCentred isOpen={modalWating} classNameContainer="border-teal-500">
        <div className="p-[30px]">
          <ActivatingSubscription />
        </div>
      </ModalCentred>
      <ModalCentred isOpen={modalError} classNameContainer="border-red-500">
        <OpsConatiner tryAgain={toggleModalError} />
      </ModalCentred>
      {/** modal error payment */}
    </OrderContext.Provider>
  );
};

const useOrderContext = () => useContext(OrderContext);
export { OrderProvider, useOrderContext };
