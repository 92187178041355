import {
  ArrowDownward,
  ArrowDropDown,
  ArrowRightRounded,
} from "@material-ui/icons";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React from "react";
import { Col, Label, Row } from "../../components/ui";
import { QUESTIONS } from "./data";
interface IQuestion {
  title: string;
  content: string;
}
const QuestionItem = ({ title, content }: IQuestion) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="border-t-2 border-gray-100/10	">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer p-3 flex justify-between"
      >
        <div>
          <span className="text-white">{title}</span>
        </div>
        <div className="text-white">
          {!open ? <ArrowRightRounded /> : <ArrowDropDown />}
        </div>
      </div>
      {open && (
        <div className="p-3">
          <span className="text-white">{content}</span>
        </div>
      )}
    </div>
  );
};
export default function FaqPage(props: any) {
  function handleBack() {
    props.history.push("/");
  }
  return (
    <React.Fragment>
      <div className="h-screen">
        <div className="my-5 h-16">
          <p className="title">
            FAQ's
          </p>
        </div>
        <Row className="my-2">
          <Col sm={1} md={1}>
            <div onClick={handleBack} className="cursor-pointer">
              <ArrowBack className="text-white" />
            </div>
          </Col>
          <Col sm={11} md={11}>
            <a onClick={handleBack}>
              <Label color="white" size="lg" className="ml-2">
                Subscriptions
              </Label>
            </a>
          </Col>
        </Row>
        <div>
          {QUESTIONS.map((question: IQuestion) => (
            <QuestionItem {...question} key={question.title} />
          ))}
        </div>

      </div>
    </React.Fragment>
  );
}
