import React from "react";
import "./Button.css";
import paypal from "../../styles/assets/img/paypal.png";
import classNames from "classnames";
export const Button = (props) => {
  return (
    <button
      className={classNames("default-btn", props?.className)}
      type="button"
      onClick={props.onClick}
      style={{
        width: props.width,
        backgroundColor: props.background ? props.background : "#f3bf59",
        position: props.fixed ? "fixed" : "relative",
        bottom: props.fixed ? "16px" : null,
      }}
    >
      {!props.paypal && props.name}
      {props.paypal && <img src={paypal} width="87px" height="22px" />}
    </button>
  );
};
