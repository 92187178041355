import { ENDPOINTS } from "../../config/constants";
import http from "../../config/http";
interface AuthProps {
  email: string;
  password: string;
}
class Authentication {
  login = ({ email, password }: AuthProps) => {
    return http.post(ENDPOINTS.AUTH, {
      username: email,
      userPassword: password,
    });
  };
  register = ({ email, password }: AuthProps) => {
    return http.post(ENDPOINTS.REGISTER, {
      email: email,
      password: password,
    });
  };
  resetPassword = ({ email }: { email: string }) => {
    return http.post(ENDPOINTS.FORGOT, { userEmail: email });
  };
}
export default new Authentication();
