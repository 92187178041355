import React from "react";
import "./CounterLocation.css";

export const CounterLocation = (props) => {
  return (
    <div className="container-counter">
      <button
        className="btn-minus"
        type="button"
        onClick={() => props.handleQuantity(-1)}
      >
        -
      </button>
      <div className="counter">{props.count}</div>
      <button
        className="btn-plus"
        type="button"
        onClick={() => props.handleQuantity(1)}
      >
        +
      </button>
    </div>
  );
};
