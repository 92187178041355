import React from "react";
import Logo from "../../components/Logo/Logo";
import { Button } from "../../components/Button/Button";
import { useHistory } from "react-router";
import { redirect } from "../../adapters/redirect";
import { PUBLIC_BASE_URL } from "../../routing/urls";
import equalizer from "../../styles/img/equalizer.gif";

export const OrderSuccess: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <div className="flex justify-center items-center w-full flex-col">
      <Logo />
      <div className="icon-oh">
        <span> 🎉🎉🎉 </span>
      </div>
      <div className="text-center text-white">
        Congratulation your order has been successfully placed
      </div>
      <Button
        name="See my subscriptions"
        width="327px"
        xs={12}
        md={12}
        onClick={() => redirect(history, PUBLIC_BASE_URL)}
      />
    </div>
  );
};
