import React from "react";
import Logo from "../../components/Logo/Logo";
import Input from "../../components/Input";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import TailwindButton from "../../components/Button/TailwindButton";
export default function Login() {
  const history = useHistory();
  const { login, isAuthenticated } = useAuth();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <div className="flex justify-center items-center w-screen h-screen ">
        <div className="flex flex-col justify-center items-center">
          <div>
            <Logo />
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "* Email address is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "* Invalid email address";
              }
              if (!values.password) {
                errors.password = "* Password is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              login(
                values,
                history,
                //onSuccess
                () => setSubmitting(false),
                //onError
                (error) => {
                  setSubmitting(false);
                  if (error.message.includes("403")) {
                    setError("Wrong email or password.");
                  } else {
                    setError(error.message);
                  }
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <div>
                <div className="mt-4">
                  <span className="text-white text-2xl">Login</span>
                </div>

                <form className="mt-5 w-full" onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    maxLength={120}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email address"
                    variant="outline"
                  />
                  <Input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={120}
                    value={values.password}
                    placeholder="Password"
                    variant="outline"
                  />
                  <div className="text-red-500 text-xs">
                    {errors.email && touched.email && errors.email} &nbsp;
                  </div>
                  <div className="text-red-500 text-xs">
                    {errors.password && touched.password && errors.password}{" "}
                    &nbsp; &nbsp;
                  </div>
                  <div
                    className="flex justify-end cursor-pointer w-[320px] "
                    onClick={() => history.push("/forgot-password")}
                  >
                    <span className="text-white text-sm">Forgot Password?</span>
                  </div>
                  <div className="w-[320px] mt-2">
                    {error && (
                      <span className="text-red-500 text-xs">
                        {" "}
                        {
                          // Make error message only first letter capitalized
                          error.charAt(0).toUpperCase() +
                            error.slice(1).toLowerCase()
                        }{" "}
                      </span>
                    )}
                  </div>

                  <div className="mt-2 flex flex-col">
                    <TailwindButton
                      type="submit"
                      className="w-[330px] rounded-full"
                      loading={isSubmitting}
                    >
                      Login
                    </TailwindButton>
                    <div
                      className="text-white text-lg mt-2 cursor-pointer text-center"
                      onClick={() => history.push("/register")}
                    >
                      Create account ?
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
