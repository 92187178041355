import React from "react";
import "./USTextField.css";
import Input, { InputProps } from "../Input";

export default function USTextField(props: InputProps) {
  const {
    name,
    value,
    variant = "outline",
    onChange,
    label,
    className,
    type = "text",
    autoComplete = "off",
    ...rest
  } = props;
  return (
    <Input
      name={name}
      type={type}
      className={className}
      placeholder={label}
      value={value}
      variant={variant}
      onChange={onChange}
      autoFocus={false}
      autoComplete={autoComplete}
      {...rest}
    />
  );
}
