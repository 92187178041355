import equalizer from "../../styles/img/equalizer.gif";

const ActivatingSubscription = () => {
  return (
    <div className="flex  justfiy-center items-center flex-col">
      <img src={equalizer} />
      <p className="text-2xl font-semibold text-white">
        Activating your subsciption
      </p>
      <small className="text-white text-center">
        This might take a few seconds
      </small>
    </div>
  );
};

export default ActivatingSubscription;
