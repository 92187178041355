import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routes from "./routing/routes";
import Login from "./pages/Authentification/Login";
import Register from "./pages/Authentification/Register";
import ForgotPassword from "./pages/Authentification/ForgotPassword";
import { ProblemModel } from "./pages";
import {
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN,
  AUTH_REGISTER,
} from "./routing/urls";

function AppRouter() {
  const keys = Object.keys(routes);

  return (
    <Router>
      <Switch>
        <Route path={AUTH_LOGIN} exact={true} component={Login} />
        <Route path={AUTH_REGISTER} exact={true} component={Register} />
        <Route
          path={AUTH_FORGOT_PASSWORD}
          exact={true}
          component={ForgotPassword}
        />
        {keys.map((layoutName) => {
          let layout = routes[layoutName];
          return routes[layoutName].children.map((route, index) => {
            return (
              <route.routeComponent
                key={index}
                exact={route.exact}
                path={route.path}
                render={(props) => (
                  <layout.component>
                    <route.component {...props} />
                  </layout.component>
                )}
              />
            );
          });
        })}
        <Route path="/error-404" exact component={ProblemModel} />
        <Redirect path="/*" to="/error-404" />
      </Switch>
    </Router>
  );
}
export default AppRouter;
