import React from "react";
import Logo from "../../components/Logo/Logo";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import TailwindButton from "../../components/Button/TailwindButton";
import Input from "../../components/Input";
export default function ForgotPassword() {
  const history = useHistory();
  const { forgotPassword, isAuthenticated } = useAuth();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="flex flex-col justify-center items-center">
          <div>
            <Logo />
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email address is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              forgotPassword(
                values,
                history,
                //onSuccess
                () => setSubmitting(false),
                //onError
                (error) => {
                  setSubmitting(false);
                  setError(error.message);
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div>
                <div className="mt-4">
                  <span className="text-white text-2xl">Forgot Password</span>
                </div>

                <form className="mt-5">
                  <div className="w-full">
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={120}
                      value={values.email}
                      placeholder="Email address"
                      variant="outline"
                      required
                    />
                    <span className="text-red-500">
                      &nbsp; {dirty && touched.email ? errors.email : ""}
                    </span>
                  </div>

                  <div className="mt-2 flex flex-col">
                    <TailwindButton
                      className="w-[330px] rounded-full"
                      onClick={!!errors.email && handleSubmit}
                      loading={isSubmitting}
                    >
                      Send
                    </TailwindButton>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
