import React, { createContext, useContext, useState } from "react";
import auth from "../repositories/auth/auth";
import Token from "../utils/Token";

export const AuthContext = createContext({});
const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  function login(values, history, onSuccess = () => {}, onError = () => {}) {
    return auth
      .login(values)
      .then((httpResponse) => {
        if (httpResponse?.data?.access_token) {
          Token.setToken(httpResponse?.data?.access_token);
          setCurrentUser(httpResponse?.data);
          history.push("/");
          onSuccess();
        }
        onSuccess();
        return httpResponse;
      })
      .catch((httpError) => {
        onError(httpError.response.data);
      });
  }
  function register(values, history, onSuccess = () => {}, onError = () => {}) {
    return auth
      .register(values)
      .then((httpResponse) => {
        if (httpResponse?.data) {
          setCurrentUser(httpResponse?.data);
          history.push("/login");
          onSuccess();
        }
        onSuccess();
        return httpResponse;
      })
      .catch((httpError) => {
        onError(httpError.response.data);
      });
  }
  function forgotPassword(
    values,
    history,
    onSuccess = () => {},
    onError = () => {}
  ) {
    return auth
      .resetPassword(values)
      .then((httpResponse) => {
        if (httpResponse?.data) {
          setCurrentUser(httpResponse?.data);
          history.push("/login");
          onSuccess(httpResponse);
        }
        onSuccess();
        return httpResponse;
      })
      .catch((httpError) => {
        onError(httpError.response.data);
      });
  }

  function logout(history) {
    Token.removeToken();
    setTimeout(function () {
      history.push("/login");
    }, 1000);
    window.location.reload();
  }
  const isAuthenticated = React.useMemo(() => {
    return Token.getToken();
  }, [Token.getToken()]);
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        forgotPassword,
        setCurrentUser,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
