import * as React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/styles';
import './Stepper.css';
import '../../pages/OrderStepper/StyleStepper.css';

// const styles = () => ({
// 	root: {
// 		'.MuiMobileStepper-root': {

// 			'& .MuiMobileStepper-dot': {
// 				color: 'red',
// 			},
// 			'& .MuiMobileStepper-dotActive': {
// 				color: 'red',
// 			}
// 		}
// 	}
// });

const Stepper = (props) => {
	const { stepsCount,
		activeStep 
	} = props;

  return (
		<MobileStepper
			style={{ justifyContent:'center', backgroundColor:'black' , color:'orange'}}
			variant='dots'
			steps={stepsCount}
			position='static'
			activeStep={activeStep}
			// className={classes.root}
			sx={{ maxWidth: 400, flexGrow: 1 }}
		/>
	);
};
// export default withStyles(styles)(Stepper);
export default  (Stepper);

Stepper.propTypes = {
	stepsCount: PropTypes.number,
	activeStep: PropTypes.number,
	setActiveStep: PropTypes.func,
	handleReset: PropTypes.func,
	handleBack: PropTypes.func,
	handleNext: PropTypes.func,
};
