/* eslint-disable linebreak-style */

import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import Logo from "../Logo/Logo";
export const SidebarItem = ({
  name,
  path,
  external,
  callback,
  isMobile,
}) => {
  const className = `text-white border-b border-gray-600 cursor-pointer ${
    isMobile ? "py-4" : "p-4 mx-4"
  }`;
  return external ? (
    <li
      className={className}
      onClick={() => {
        callback?.();
      }}
    >
      {name}
    </li>
  ) : (
    <Link to={path} onClick={callback}>
      <li className={className}>{name}</li>
    </Link>
  );
};

export const menu = [
  {
    name: "Pricing & Subscription",
    path: "/",
  },
  {
    name: "FAQ’s",
    path: "/faq",
  },
  {
    name: "Terms and conditions",
    path: "/terms",
    external: true,
    url: "https://www.websitepolicies.com/policies/view/GSDXNXkZ",
  },
  {
    path: "/privacy",
    name: "Privacy policy",
    external: true,
    url: "https://www.websitepolicies.com/policies/view/bTz4WVcp",
  },
];
export const LeftNavbar = ({ className, callback, isMobile = false }) => {
  const { logout } = useAuth();
  const history = useHistory();
  return (
    <div className={`overflow-y-auto bg-BlackGray h-screen w-96 ${className}`}>
      <nav className="h-full text-sm">
        {!isMobile && (
          <div className="p-10">
            <Logo />
          </div>
        )}

        <ul>
          {menu?.map((item, i) => (
            <div className={isMobile ? "pr-4" : ""}>
              <SidebarItem
                {...item}
                key={item?.path}
                callback={() => {
                  callback?.();
                  if (item.external) {
                    window.open(item.url, "_blank");
                  }
                }}
                isMobile={isMobile}
              />
            </div>
          ))}
          <div className={isMobile ? "pr-4" : ""}>
            <SidebarItem
              name="Logout"
              isMobile={isMobile}
              external={true}
              callback={() => {
                callback?.();
                logout(history);
              }}
            />
          </div>
        </ul>
      </nav>
    </div>
  );
};
