import React from "react";
import classNames from "classnames";
interface Props {
  children: React.ReactNode;
  className?: string;
  size?: "sm" | "md" | "lg" | "xl" | "3xl";
  font?: "bold" | "semibold" | undefined;
  color?: string;
}
export default function Label({
  children,
  className,
  size,
  font,
  color,
}: Props) {
  let customClassName: string = "";

  if (size) {
    customClassName += `text-${size} `;
  }
  if (font) {
    customClassName += `font-${font} `;
  }
  if (color) {
    customClassName += `text-${color} `;
  }

  return (
    <span className={classNames(customClassName, className)}>{children}</span>
  );
}
