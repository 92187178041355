import React from "react";

interface Props { 
  title: string;
  paragraph: string;
}
export default function ParagraphPage(props: Props) {
  return (
    <React.Fragment>
      <div className="h-screen">
        <div className="my-5 h-16">
          <p className="title">
            {props.title}
          </p>
        </div>
        <div className="mx-4 overflow-auto">
          <p className="text-white mb-12">
            {props.paragraph}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}