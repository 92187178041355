import http from "../config/http";
interface ISubscription {
  locationsNumber: number;
  paymentMethod: "STRIPE" | "PAYPAL";
  paymentToken: string;
  paypalSubscriptionId?: string;
  businessType: string;
  vatID: string;
  country: string;
}
interface IContact {
  body: string;
}
class Invoice {
  constructor(private httpClient = http) {}
  subscriptions(data: ISubscription) {
    return this.httpClient.post("/subscriptions", data);
  }
  getSubscriptions() {
    return this.httpClient.get("/subscriptions");
  }
  cancelSubscription(id: number) {
    return this.httpClient.delete(`subscriptions/${id}`);
  }
  contactUs(data: IContact) {
    return this.httpClient.post("/users/contactUs", data);
  }
  
  stripe_session(quantity: number) {
    return this.httpClient.post(
      "subscriptions/session",
      {},
      { params: { quantity } }
    );
  }
}
export default new Invoice();
