import { Formik } from "formik";
import { Button } from "../../components/Button/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import SelectField from "../../components/SelectField";
import "./StyleStepper.css";

export default function OrderStepZero({ initialValues, onSubmit, onChangeInput }) {
  const { type = '' } = initialValues ?? {};
  return (
    <>
      <Formik
        initialValues={{
          type: type,
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          let errors = {}
          if (!values.type || values.type.length === 0) {
            errors.type = '* Business type is required'
          }
          return errors
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit
        }) => (
          <div>
            <GridItem>
              <SelectField
                name="type"
                options={[
                  { value: "", label: "--Select Type--" },
                  { value: 'business', label: 'Business' },
                  { value: 'private', label: 'Private' },
                ]}
                value={values.type}
                onChange={(event) => {
                  handleChange(event)
                  onChangeInput(event)
                }}
              />
            </GridItem>
            <GridItem className="w-full ml-8">
              {<div className="text-red-500"> { !!errors.type ? errors.type : ''} </div>}
            </GridItem>
            <GridItem>
              <Button
                type="button"
                width="327px"
                onClick={handleSubmit}
                name={"Next"}
              />
            </GridItem>
          </div>
        )}
      </Formik>
    </>
  );
}
