export const QUESTIONS = [
  {
    title: 'Warum muss ich wenn ich ilioa verwende keine Abgaben an Verwertungsgesellschaften (AKM, GEMA, SUISSA) zahlen?',
    content: 'Verwertungsgesellschaften (PRO\'s) haben lediglich das Recht, Gelder für ihre Mitglieder einzutreiben. Sämtliche Musik, welche in der App zur Verfügung steht, stammt von Komponisten aus dem ilioa-Team und ist somit nicht durch Verwertungsgesellschaften geschützt.'
  },
  {
    title: 'Wie kann ich die App an mehreren Orten gleichzeitig verwenden?',
    content: 'Beim erstellen oder bearbeiten Ihres Accounts, können Sie die Anzahl Ihrer Lizenzen auswählen. Wenn sie also zB. 3 Lizenzen erwerben, können Sie problemlos Musik an 3 unterschiedlichen Orten gleichzeitig abspielen!'
  },
  {
    title: 'Wie lange ist ilioa gratis?',
    content: 'Wir bieten jedem unserer Neukunden, an beliebig vielen Standorten einen kostenlosen Testzeitraum von 14 Tagen an.'
  },
  {
    title: 'Wie finden Updates statt?',
    content: 'Musikalische, wie auch technische Updates passieren automatisch!'
  },
  {
    title: 'Kann ich die Zahlung bei Saisonalbetrieben pausieren',
    content: 'Natürlich, Ihre Lizenz ist bis zu Ihrem nächsten Zahlungstermin jederzeit kündbar!'
  },
];
