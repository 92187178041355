import React from "react";
import { useHistory } from "react-router-dom";
import { PATH_URLS } from "../routing/urls";
export const redirect = (history, url, params) => {
  history.push({
    pathname: `${url}`,
    state: params,
  });
};
export const useRedirectUnkownPath = () => {
  const history = useHistory();

  React.useEffect(() => {
    history.listen((location, action) => {
      if (!PATH_URLS.includes(location?.pathname)) {
        history.push("/error-404");
      }
    });
  }, []);
};
