import React from "react";
import classNames from "classnames";
interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function Row({ children, className }: Props) {
  return (
    <div className={classNames("flex flex-wrap", className)}>{children}</div>
  );
}
