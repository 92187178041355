import { Formik } from "formik";
import { Button } from "../../components/Button/Button";
import GridItem from "../../components/Grid/GridItem";
import USTextField from "../../components/TextField/USTextField";
import "./StyleStepper.css";

export default function OrderStepOne({
  initialValues,
  onSubmit,
  onChangeInput,
}) {
  const { firstName = "", lastName = "", phone = "" } = initialValues ?? {};
  return (
    <>
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          phone: phone,
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          let errors = {};
          if (!values.firstName || values.firstName.length === 0) {
            errors.firstName = "* First name is required";
          }
          if (!values.lastName || values.lastName.length === 0) {
            errors.lastName = "* Last name is required";
          }
          if (!!values.phone) {
            if (!/^\+?\d+$/i.test(values.phone)) {
              errors.phone = "* Invalid phone number";
            }
          }
          return errors;
        }}
      >
        {({ values, touched, dirty, errors, handleChange, handleSubmit }) => (
          <div>
            <GridItem>
              <form>
                <USTextField
                  type="text"
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                  onChange={(event) => {
                    handleChange(event);
                    onChangeInput(event);
                  }}
                />
              </form>
            </GridItem>
            <GridItem>
              <USTextField
                name="lastName"
                label="Last name"
                value={values.lastName}
                onChange={(event) => {
                  handleChange(event);
                  onChangeInput(event);
                }}
              />
            </GridItem>
            <GridItem>
              <USTextField
                name="phone"
                label="Phone number (optional)"
                type="tel"
                value={values.phone}
                onChange={(event) => {
                  handleChange(event);
                  onChangeInput(event);
                }}
              />
            </GridItem>
            <GridItem className="w-full ml-8">
              {touched.firstName && (
                <div className="text-red-500"> {errors.firstName} </div>
              )}
              {touched.lastName && (
                <div className="text-red-500"> {errors.lastName} </div>
              )}
              {touched.phone && (
                <div className="text-red-500"> {errors.phone} </div>
              )}
            </GridItem>
            <GridItem>
              <Button
                type="button"
                width="327px"
                onClick={handleSubmit}
                name={"Next"}
              />
            </GridItem>
          </div>
        )}
      </Formik>
    </>
  );
}
