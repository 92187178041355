import axios from "axios";
import { API_ENDPOINT, STRIPE_ENDPOINT, STRIPE_PUBLIC_KEY } from "../config/constants";
interface CardProps {
  cardNumber: string;
  ccv: string;
  mm: string;
  yy: string;
  address: string;
  companyName: string;
  country: string;
  name: string;
  phone: string;
  city: string;
  zipCode: string;
  type: string;
}
class Payment {
  getStripeCardToken({
    cardNumber,
    ccv,
    mm,
    yy,
    address,
    city,
    companyName,
    country,
    name,
    phone,
    zipCode,
    type,
  }: CardProps) {
    return axios({
      method: "post",
      url: `${STRIPE_ENDPOINT}?${this.formatCreditCard({
        cardNumber,
        ccv,
        mm,
        yy,
        companyName,
        country,
        name,
        city,
        zipCode,
        address,
        phone,
        type,
      })}`,
      headers: {
        Authorization: `Bearer ${STRIPE_PUBLIC_KEY}`,
      },
    })
      .then((httpResponse) => {
        return httpResponse.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  private formatCreditCard({
    cardNumber,
    ccv,
    mm,
    yy,
    companyName,
    country,
    name,
    city,
    zipCode,
    address,
    phone,
    type,
  }: CardProps) {
    const itemsQuery = [
      `card[number]=${cardNumber}`,
      `card[exp_month]=${mm}`,
      `card[exp_year]=${yy}`,
      `card[cvc]=${ccv}`,
      `currency=eur`,
      `card[address_line1]=${companyName}`,
      `card[address_line2]=${companyName}`,
      `card[address_state]=${address}`,
      `card[address_country]=${country}`,
      `card[address_zip]=${zipCode}`,
      `card[address_city]=${city}`,
      `card[name]=${name}`,
    ];
    return itemsQuery.join("&");
  }
}
export default new Payment();
