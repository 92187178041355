import cn from "classnames";
import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  label?: string;
  note?: string;
  error?: string;
  type?: string;
  maxLength?: number;
  shadow?: boolean;
  variant?: "normal" | "solid" | "outline";
}
const classes = {
  root: "rounded-full outline-none border-0 w-[327px] px-4 h-12 flex items-center w-full appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0",
  normal:
    "bg-BlackGray border-b  focus:bg-light focus:border-accent text-white",
  solid:
    "bg-gray-100 border border-border-100 focus:bg-light focus:border-accent",
  outline:
    "bg-BlackGray border border-border-base focus:border-accent text-white",
  shadow: "focus:shadow",
};
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      note,
      name,
      error,
      maxLength,
      children,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === "normal",
        [classes.solid]: variant === "solid",
        [classes.outline]: variant === "outline",
      },
      {
        [classes.shadow]: shadow,
      },
      inputClassName
    );

    return (
      <div className={className}>
        <label
          htmlFor={name}
          className="block text-white font-semibold text-sm leading-none mb-3"
        >
          {label}
        </label>
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          maxLength={maxLength}
          className={rootClassName}
          autoComplete="none"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          aria-invalid={error ? "true" : "false"}
          {...rest}
        />
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {!!error && (
          <p className="my-2 text-xs text-start text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

export default Input;