import React from "react";
import classNames from "classnames";
interface Props {
  isOpen: boolean;
  toogleModal: () => void;
  modalTitle?: string;
  children: React.ReactElement;
  closeIcon: boolean;
  classNameContainer: string;
}
export default function ModalCentred({
  isOpen,
  toogleModal,
  modalTitle,
  children,
  closeIcon,
  classNameContainer,
}: Props) {
  return isOpen ? (
    <div
      className={classNames(
        "justify-center items-center flex ",
        "overflow-x-hidden overflow-y-auto ",
        "fixed inset-0 z-50 outline-none focus:outline-none",
        "bg-black/75"
      )}
      // onClick={toogleModal}
    >
      <div className="relative w-auto  ">
        {/*content*/}
        <div
          className={classNames(
            "rounded-2xl ",
            "shadow-lg relative flex flex-col",
            "w-full bg-black outline-none focus:outline-none",
            "border border-solid border-gray-500",
            "shadow-lg",
            classNameContainer
          )}
        >
          {/*header*/}
          <div className="flex items-center justify-between p-5  rounded-t">
            {modalTitle && (
              <h3 className="text-xl font-semibold text-primary">
                {modalTitle}
              </h3>
            )}
            {closeIcon && (
              <button
                className="p-1 ml-auto  text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={toogleModal}
              >
                <span className="bg-transparent text-white text-3xl h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            )}
          </div>
          {/*body*/}
          <div className="p-10 flex justify-center items-center">
            {children}
          </div>

          {/*footer*/}
        </div>
      </div>
    </div>
  ) : null;
}
