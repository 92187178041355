import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/poppins"; // Defaults to weight 400.

import "./assets/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Toaster position="top-right" reverseOrder={false} />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      theme="dark"
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={2}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
